body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-notice{
    position: relative;
    text-align: center;
    padding: 4% 0;
    max-width: 760px;
    margin: 0 auto; 
}
.sb-form{
  margin-top: 20px !important;
}
.rdt_TableHeadRow{
  background-color: #0e416d !important
}
.rdt_TableRow{
  border: solid 1px;
}
.rdt_TableCol{
  color: white !important;
}
.slider-banner .owl-item{
	height: 300px;
}
.error{
  color: red;
}
.slider-banner .slide{
	min-height: 300px !important;
}
.applications-padding{
  padding: 25px 0 !important;
}
.about-padding{
  margin-right: 10px;
}
.applications{
  line-height: 1.2 !important;
  height: 50px !important;
}
.application-width{
  width: 300px !important;
}
.application-li{
  height: 350px !important;
  overflow-x: auto;
}

.attestion-li{
  height: 270px !important;
  overflow-x: auto;
}