/*-------------------------------------
    Template Name: Elearning - Multipurpose React Education Template
    Template URI: https://themeies.com
    Description: Elearning is the most Enticing, Creative and Modern Education template
    Author: Themeies
    Author URI: https://themeies.com
    Version: 1.0
    Tags: education, learn, elearn, tutor, LMS, University
---------------------------------------*/
/*
    Table of Context
    -------------------------------
    * Common CSS
    * Preloader
    * Header style
    * Navigation menu
    * Form Section
    * Feature Section 
    * Tutor Section 
    * Call to action Section 
    * Nearby students section
    * Blog section
    * Courses section
    * Course details section
    * Course video
    * Course Curriculum
    * Comments
    * Contact section
    * Pricing section
    * Page Feature
    * Login / Registration
    * Footer
*/

/**
 * Fonts 
 * Roboto & Popins
 */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,700&display=swap");


* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  color: #83868c;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
}

a {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

a:hover, a:focus {
  text-decoration: none;
  outline: 0px;
}

h1, h2, h3, h4, h5, h6 {
  color: #0c2e60;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

input:active, input:focus {
  outline: 0;
}

section {
  display: block;
  position: relative;
  padding: 70px 0;
  width: 100%;
}

@media (max-width: 767px) {
  br {
    display: none;
  }
}

/**
 *
 * Common CSS
 *
 */
@media screen and (max-width: 767px) {
  section {
    padding-bottom: 50px;
  }
}

.sec-heading {
  margin-bottom: 80px;
}

.sec-heading .sec-title {
  font-size: 42px;
  margin-bottom: 0;
}

@media screen and (max-width: 1199px) {
  .sec-heading .sec-title {
    font-size: 38px;
  }
}

@media screen and (max-width: 991px) {
  .sec-heading .sec-title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  .sec-heading .sec-title {
    font-size: 30px;
  }
}

@media screen and (max-width: 479px) {
  .sec-heading .sec-title {
    font-size: 26px;
  }
}

@media screen and (min-width: 768px) {
  .nopadd-letf {
    padding-left: 0;
  }
  .nopadd-right {
    padding-right: 0;
  }
  .nopadd {
    padding-left: 0;
    padding-right: 0;
  }
}

.nopadd-btm {
  padding-bottom: 0 !important;
}

.btn {
  border: none;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 0.8;
  text-transform: uppercase;
  padding: 20px 40px;
}

.btn-filled {
  border-radius: 0;
  background: -webkit-gradient(linear, left top, right top, from(#ef3e0f), to(#ffb800));
  background: linear-gradient(to right, #ef3e0f, #ffb800);
}

.btn-filled:hover {
  background-color: #fff;
  -webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
}

.btn-default {
  background: -webkit-gradient(linear, left top, right top, from( #2d6968), to( #10e4e0));
  background: linear-gradient(to right, #2d6968, #10e4e0);
  border-radius: 50px;
  color: #fff;
}

.btn-default:hover {
  background-color: #fff;
  -webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
}

.btn-round {
  border-radius: 50px;
}

.btn-outline {
  border: 1px solid #83868c;
  color: #83868c;
}

.btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn.icon-left i {
  line-height: .8;
  margin-right: 20px;
}

.tagline {
  color: #2d6968;
  display: block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  margin-bottom: 10px;
  position: relative;
}

@media screen and (max-width: 991px) {
  .tagline {
    font-size: 12px;
  }
}

.tagline.bar {
  padding-left: 70px;
}

.tagline.bar:after {
  background-color: #0c2e60;
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 2px;
  width: 50px;
}

.content-box h2, .content-box-fluid h2 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 23px;
}

@media screen and (max-width: 479px) {
  .content-box h2, .content-box-fluid h2 {
    font-size: 26px;
    line-height: 34px;
  }
}

.content-box h5, .content-box-fluid h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 30px;
}

.content-box .btn, .content-box-fluid .btn {
  margin-top: 25px;
}

.content-box-fluid {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .content-box-fluid {
    max-width: 540px;
  }
}

@media screen and (min-width: 768px) {
  .content-box-fluid.right {
    float: right;
  }
}

.circle {
  border-radius: 50%;
}

.video-box {
  position: relative;
}

.video-box:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(22, 43, 69, 0.7);
}

.video-box .video-in {
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  z-index: 1;
}

.video-box .video-in span {
  color: #fff;
}

.read-more {
  color: #0c2e60;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 2px solid #e8e9eb;
  display: inline-block;
}

.read-more:hover {
  color: #ef470d;
}

.bg-offwhite {
  background: #f6f7f8;
}

.avatar {
  position: relative;
}

.avatar-small {
  height: 60px;
  width: 60px;
}

.avatar-md {
  height: 100px;
  width: 100px;
}

.card {
  position: relative;
  border: none;
  padding: 25px;
  -webkit-box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
          box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
}

.process {
  position: relative;
  padding: 25px;
}

.process h3 {
  font-size: 30px;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  text-transform: capitalize;
  padding: 15px 0;
  z-index: 1;
}

.process h3::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100% 50%;
  margin: 0 auto;
  background-color: #f1f6f9;
  height: 57px;
  width: 88px;
  z-index: -1;
}

.process li {
  position: relative;
  padding-left: 110px;
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 80px;
  z-index: 1;
}

.process li:last-child {
  padding-bottom: 0;
}

@media screen and (min-width: 480px) {
  .process li:not(:first-child)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 40px;
    right: 0;
    bottom: auto;
    background-color: #eee;
    width: 1px;
    height: 50%;
  }
  .process li:not(:last-child)::after {
    content: '';
    position: absolute;
    top: auto;
    left: 40px;
    right: 0;
    bottom: 0;
    background-color: #eee;
    width: 1px;
    height: 50%;
    z-index: -1;
  }
}

.process li:hover .icon-bg {
  background: -webkit-gradient(linear, left top, right top, from(#ef3e0f), to(#ffb800));
  background: linear-gradient(to right, #ef3e0f, #ffb800);
}

.process li .icon-bg {
  border-radius: 50%;
  background-color: #f1f6f9;
  display: block;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  height: 80px;
  width: 80px;
}

.process li img {
  position: relative;
  max-width: 36px;
  width: auto;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.process li h5 {
  font-size: 22px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.process li h5 a {
  color: #0c2e60;
}

.process li h5 a:hover {
  color: #ef470d;
}

.process li p {
  margin-bottom: 0;
}

@media screen and (max-width: 479px) {
  .process {
    padding: 0;
  }
  .process li {
    padding-left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
  .process li img {
    max-width: 24px;
  }
  .process li .icon-bg {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: none;
            transform: none;
    margin: 0 auto 20px;
    height: 60px;
    width: 60px;
  }
}
.span-family{
  font-weight: 700;
}
.icon-list-block {
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #efefef;
  border-radius: 5px;
  display: block;
  padding: 15px 25px;
  margin-bottom: 30px;
  cursor: pointer;
}

@media screen and (max-width: 1199px) {
  .icon-list-block {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 991px) {
  .icon-list-block {
    text-align: center;
    padding: 15px;
  }
}

.icon-list-block:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.icon-list-block img {
  display: inline-block;
  margin-right: 20px;
  width: 30px;
}

@media screen and (max-width: 1199px) {
  .icon-list-block img {
    margin-right: 15px;
  }
}

@media screen and (max-width: 991px) {
  .icon-list-block img {
    display: block;
    margin: 0 auto;
  }
}

.icon-list-block i {
  display: inline-block;
  margin-right: 20px;
  font-size: 20px;
}

.icon-list-block span {
  color: #2f2f35;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  .icon-list-block span {
    line-height: 1.5;
  }
}

@media screen and (max-width: 991px) {
  .icon-list-block span {
    line-height: 1;
  }
}

@media screen and (max-width: 1199px) {
  .icon-list-block span {
    font-size: 15px;
  }
}

/**
 *  Preloader
 */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 99999999;
  width: 100%;
  height: auto;
}

#status {
  width: 250px;
  height: 250px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url(../images/preloader.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/**
 * Header style
 */
.header {
  display: block;
  position: relative;
  padding: 0px 0;
  width: 100%;
}

.header .btn {
  margin-left: 15px;
  text-transform: uppercase;
  padding: 13px 25px;
}

.header03 .navbar {
  background-color: #000;
  max-width: 1370px;
  margin: 0 auto -40px;
  position: relative;
  z-index: 1;
}

.header03 .navbar li a {
  color: #fff;
}

.header03 .search-icon, .header03 .cart-bag, .header03 .cart-bag {
  color: #fff;
}

.logo {
  display: block;
  max-width: 110px;
}

.search-icon, .cart-bag {
  color: #000;
  font-size: 16px;
  padding: 0 5px;
  position: relative;
}

.search-icon:hover, .cart-bag:hover {
  color: #0c2e60;
}

.cart-bag .itemCount {
  background-color: #ee4f36;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 14px;
  width: 14px;
}

.cart-bag .itemCount:hover {
  color: #0c2e60;
}

.abs-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

@media screen and (max-width: 991px) {
  .abs-header {
    background-color: #fff;
  }
}

/**
 * Navigation menu
 */
.navbar {
  padding: 0;
}

@media screen and (max-width: 991px) {
  .navbar {
    position: inherit;
  }
}

.navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.navbar-nav li {
  position: relative;
}

.navbar-nav li a {
  color: #0c2e60;
  display: block;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  line-height: 0.8;
  padding: 25px 15px;
}

.navbar-nav li a li.current-menu-item a, .navbar-nav li a:hover {
  color: #ef470d;
}

.navbar-nav li .sub-menu {
  background-color: #fff;
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.07);
          box-shadow: 0 2px 15px rgba(0, 0, 0, 0.07);
}

@media screen and (min-width: 992px) {
  .navbar-nav li .sub-menu {
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    width: 260px;
    z-index: 9;
  }
}

@media screen and (max-width: 991px) {
  .navbar-nav li .sub-menu {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .navbar-nav li .sub-menu a {
    /*padding: 20px;*/
  }
}

.navbar-nav li .sub-menu a:hover {
  background-color: #f5f5f5;
}

@media screen and (min-width: 992px) {
  .navbar-nav li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 991px) {
  .navbar-nav {
    background-color: #fff;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 8;
  }
  .navbar-nav li a {
    padding: 20px 35px;
  }
  .navbar-nav li a:hover {
    background-color: #f5f5f5;
  }
}

@media screen and (max-width: 575px) {
  .navbar-nav li a {
    padding: 20px 15px;
  }
}

#mobile-menu-toggler {
  color: #000;
  font-size: 20px;
  margin-left: auto;
  margin-right: 10px;
}

#mobile-menu-toggler:hover {
  color: #0c2e60;
}

@media screen and (min-width: 992px) {
  #mobile-menu-toggler {
    display: none;
  }
}

.menu-dropdown {
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  right: -10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

@media screen and (min-width: 992px) {
  .menu-dropdown {
    font-size: 9px;
    right: -5px;
  }
}

@media screen and (max-width: 991px) {
  .menu-dropdown {
    right: 15px;
    top: 15px;
    -webkit-transform: none;
            transform: none;
  }
}

/**
 * Header style
 */
 .marguee{
    border: solid 1px;
    border-color: blueviolet;
    color: #2d6968;
 }
.header {
  display: block;
  position: relative;
  /*padding: 20px 0;*/
  width: 100%;
}

.header .btn {
  margin-left: 15px;
  text-transform: uppercase;
  padding: 13px 25px;
}

.abs-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

@media screen and (max-width: 991px) {
  .abs-header {
    background-color: #fff;
  }
}

.banner {
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  padding: 15% 0 10%;
}

@media screen and (max-width: 1199px) {
  .banner {
    padding-top: 15%;
  }
}

@media screen and (max-width: 767px) {
  .banner {
    padding-top: 130px;
  }
}

.banner .vector-img {
  width: 31%;
  position: absolute;
  right: 10%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .banner .vector-img {
    display: none;
  }
}

/* .ban-01 {
  background-image: url(../images/ban-01.jpg);
}

.ban-02 {
  background-image: url(../images/slider/1.jpg);
  padding: 7% 0;
} */

.ban-02:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(12, 46, 96, 0.9);
}

.ban-02:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-clip-path: polygon(100% 65%, 100% 100%, 0 100%);
          clip-path: polygon(100% 65%, 100% 100%, 0 100%);
  height: 100%;
  width: 100%;
}

.ban-02 .banner-content h2, .ban-02 .slider-content h2 {
  color: #fff;
}

.ban-02 .banner-content h2 span, .ban-02 .slider-content h2 span {
  color: #3aaba9;
}

.ban-02 .banner-content p, .ban-02 .slider-content p {
  color: #fff;
}

@media screen and (max-width: 767px) {
  .ban-02 .banner-content, .ban-02 .slider-content {
    text-align: center;
    margin-bottom: 40px;
  }
}

.ban-02 .trial-form {
  -webkit-box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.07);
          box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.07);
  padding-top: 25px;
}

.banner-content h2, .slider-content h2 {
  color: #3aaba9;
  font-size: 22px;
  font-weight: 700;
  line-height: 75px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

@media screen and (max-width: 1199px) {
  .banner-content h2, .slider-content h2 {
    font-size: 49px;
    line-height: 60px;
  }
}

@media screen and (max-width: 479px) {
  .banner-content h2, .slider-content h2 {
    font-size: 44px;
    line-height: 54px;
  }
}

.banner-content h2 span, .slider-content h2 span {
  color: #3aaba9;
  display: block;
}

.banner-content p, .slider-content p {
  color: #888;
  margin-bottom: 50px;
}

/**
 * Slider Section
 */
.slider-banner .container {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0 auto;
  z-index: 2;
}

.slider-banner .search-form {
  position: relative;
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media screen and (min-width: 480px) {
  .slider-banner .search-form {
    margin-top: 60px;
  }
}

.slider-banner .search-form .nice-select {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  color: #000;
  -ms-flex-preferred-size: 180px;
      flex-basis: 180px;
  line-height: 55px;
  padding-left: 35px;
  height: auto;
}

.slider-banner .search-form .Dropdown-control {
  background-color: transparent;
  border: none;
  padding: 0;
  text-align-last: left;
}

.slider-banner .search-form .Dropdown-option {
  padding: 0;
}
.slider-banner .search-form .Dropdown-arrow {
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

@media screen and (max-width: 479px) {
  .slider-banner .search-form .nice-select {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    border-radius: 50px;
  }
}

.slider-banner .search-form input {
  background-color: #fff;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  -ms-flex-preferred-size: calc(100% - 280px);
      flex-basis: calc(100% - 280px);
  padding: 15px 20px;
  width: 100%;
}

.slider-banner .search-form input::-webkit-input-placeholder {
  color: #83868c;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.slider-banner .search-form input:-ms-input-placeholder {
  color: #83868c;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.slider-banner .search-form input::-ms-input-placeholder {
  color: #83868c;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.slider-banner .search-form input::placeholder {
  color: #83868c;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

@media screen and (max-width: 575px) {
  .slider-banner .search-form input {
    -ms-flex-preferred-size: calc(100% - 180px);
        flex-basis: calc(100% - 180px);
    border-radius: 0 50px 50px 0;
  }
}

@media screen and (max-width: 479px) {
  .slider-banner .search-form input {
    margin-top: 15px;
    border-radius: 50px;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}

.slider-banner .search-form button {
  background: -webkit-gradient(linear, left top, right top, from(#ef3e0f), to(#ffb800));
  background: linear-gradient(to right, #ef3e0f, #ffb800);
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0 50px 50px 0;
  color: #fff;
  font-size: 20px;
  -ms-flex-preferred-size: 100px;
      flex-basis: 100px;
  padding: 15px 20px;
  text-align: center;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  width: 60px;
}

.slider-banner .search-form button:hover {
  color: #fff;
  background-color: #ef470d;
}

@media screen and (max-width: 575px) {
  .slider-banner .search-form button {
    margin-top: 15px;
    border-radius: 50px;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}

.slider-carousel {
  position: relative;
}

.slider-carousel .slide {
  padding: 10% 0;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  min-height: 600px;
}

@media screen and (max-width: 767px) {
  .slider-carousel .slide {
    min-height: 450px;
  }
}

.slider-carousel:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: -webkit-gradient(linear, left top, right bottom, from(rgba(12, 46, 96, 0.8)), to(rgba(239, 71, 13, 0.5))); */
  /* background: linear-gradient(to right bottom, rgba(12, 46, 96, 0.8), rgba(239, 71, 13, 0.5)); */
  height: 100%;
  width: 100%;
}

.slider-content h2 {
  color: #fff;
}

@media screen and (max-width: 479px) {
  .slider-content h2 {
    font-size: 34px;
    line-height: 44px;
  }
}

.slider-content h2 span {
  color: #ff0c0c;

}

/**
 * Form Section 
 */
.main-form {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 1200px) {
  .nm-top-130 {
    margin-top: -130px;
  }
}

.m-search-form {
  background-color: #fff;
  -webkit-box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
          box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
  padding: 50px;
}

.m-search-form form {
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.m-search-form input, .m-search-form select, .m-search-form button {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 0 7px;
}

.m-search-form input, .m-search-form select {
  border: 1px solid #e0e4e6;
  border-radius: 0;
  color: #999;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  padding: 20px;
}

.m-search-form input::-webkit-input-placeholder, .m-search-form select::-webkit-input-placeholder {
  color: #999;
  text-transform: uppercase;
}

.m-search-form input:-ms-input-placeholder, .m-search-form select:-ms-input-placeholder {
  color: #999;
  text-transform: uppercase;
}

.m-search-form input::-ms-input-placeholder, .m-search-form select::-ms-input-placeholder {
  color: #999;
  text-transform: uppercase;
}

.m-search-form input::placeholder, .m-search-form select::placeholder {
  color: #999;
  text-transform: uppercase;
}

.m-search-form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.m-search-form button {
  background-color: #0c2e60;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

@media screen and (max-width: 991px) {
  .m-search-form input, .m-search-form select, .m-search-form button {
    -webkit-box-flex: inherit;
        -ms-flex-positive: inherit;
            flex-grow: inherit;
    -ms-flex-preferred-size: calc(100% / 2 - 10px);
        flex-basis: calc(100% / 2 - 10px);
    margin: 0;
    margin-bottom: 15px;
  }
  .m-search-form button {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    padding: 10px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 575px) {
  .m-search-form {
    padding: 40px 30px;
  }
  .m-search-form input, .m-search-form select, .m-search-form button {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}

/**
 * Services Section 
 */
.services {
  position: relative;
}

@media screen and (max-width: 767px) {
  .services {
    padding-bottom: 20px;
  }
}

.iconBox {
  position: relative;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .iconBox {
    margin-bottom: 30px;
  }
}

.iconBox .img-icon {
  max-width: 88px;
  width: auto;
  margin: 0 auto 45px;
}

.iconBox h5 {
  margin-bottom: 30px;
}

.iconBox h5 a {
  font-size: 22px;
  color: #0c2e60;
}

.iconBox p {
  margin-bottom: 27px;
}

/**
 * Feature Box Section 
 */
.features {
  background-color: #0c2e60;
  padding: 40px 0;
}

.features .sfBox {
  color: #fff;
  padding-left: 70px;
  position: relative;
}

.features .sfBox i {
  font-size: 52px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media screen and (max-width: 991px) {
  .features .sfBox i {
    position: relative;
    top: 0;
    -webkit-transform: none;
            transform: none;
    margin-bottom: 20px;
  }
}

.features .sfBox strong {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  .features .sfBox strong {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.features .sfBox strong, .features .sfBox span {
  display: block;
}

@media screen and (max-width: 991px) {
  .features .sfBox {
    padding-left: 0;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .features .sfBox {
    margin-bottom: 30px;
  }
}

.features.boxed {
  background-color: transparent;
}

.features.boxed .sfBox {
  background-color: #0c2e60;
  border-radius: 5px;
  padding: 40px 25px 40px 100px;
}

@media screen and (min-width: 992px) {
  .features.boxed .sfBox i {
    left: 25px;
  }
}

@media screen and (max-width: 991px) {
  .features.boxed .sfBox {
    padding-left: 25px;
  }
}

/**
 * Tutor Section 
 */
.tutor h3 {
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 0;
}

@media screen and (max-width: 991px) {
  .tutor h3 {
    font-size: 34px;
    line-height: 42px;
  }
}

@media screen and (max-width: 767px) {
  .tutor h3 {
    margin-bottom: 30px;
    text-align: center;
  }
}

.tutor .video-box {
  margin-bottom: 30px;
}

.tutor-list, .recent-courses, .recent-posts {
  background-color: #fff;
  padding: 20px;
  height: 450px;
  overflow-y: scroll;
}

.tutor-list li, .recent-courses li, .recent-posts li {
  border-bottom: 1px solid #eee;
  min-height: 70px;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.tutor-list li a, .recent-courses li a, .recent-posts li a {
  display: block;
  position: relative;
  padding-left: 160px;
}

@media screen and (max-width: 991px) {
  .tutor-list li a, .recent-courses li a, .recent-posts li a {
    padding-left: 105px;
  }
}

.tutor-list li a img, .recent-courses li a img, .recent-posts li a img {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 110px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .tutor-list li a img, .recent-courses li a img, .recent-posts li a img {
    max-width: 90px;
  }
}

.tutor-list li a h5, .recent-courses li a h5, .recent-posts li a h5 {
  font-size: 18px;
  font-size: 500;
  color: #000;
  margin-bottom: 10px;
}

.tutor-list li a span, .recent-courses li a span, .recent-posts li a span {
  color: #83868c;
  display: block;
  font-size: 13px;
  font-weight: 300;
  line-height: 22px;
}

.skills {
  text-transform: capitalize;
}

.infoBox {
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .infoBox {
    margin-bottom: 30px;
  }
}

.infoBox .details {
  position: relative;
  padding-left: 80px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.infoBox .details .avatar-small {
  position: absolute;
  top: 0;
  left: 0;
}

.infoBox .details h4 {
  font-size: 22px;
  margin-bottom: 0;
}

.infoBox .details span {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  color: #83868c;
}

@media screen and (max-width: 991px) {
  .infoBox .details span {
    font-size: 12px;
    line-height: 18px;
  }
}

.infoBox .hire {
  text-align: right;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.infoBox .hire .btn {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 15px 30px;
}

.infoBox .hire p {
  color: #83868c;
  font-size: 14px;
  line-height: 18px;
}

.infoBox .hire p strong {
  color: #0c2e60;
  font-size: 18px;
  font-weight: 500;
}

.video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: .5;
  z-index: 1;
}

.video-box:hover .video-btn {
  opacity: 1;
}

.ratings a {
  display: inline-block;
  color: #f98f05;
}

.ratings span {
  display: inline-block;
}

.ratings .enrolled {
  display: inline-block;
  font-size: 13px;
  margin-left: 0;
}

.subject-searchform {
  position: relative;
  max-width: 330px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .subject-searchform {
    max-width: 100%;
  }
}

.subject-searchform input {
  background-color: #f8f9fa;
  border: none;
  border-radius: 0;
  font-size: 12px;
  font-weight: 300;
  line-height: .8;
  padding: 20px 35px 20px 20px;
  width: 100%;
}

.subject-searchform input::-webkit-input-placeholder {
  color: #999;
  text-transform: uppercase;
}

.subject-searchform input:-ms-input-placeholder {
  color: #999;
  text-transform: uppercase;
}

.subject-searchform input::-ms-input-placeholder {
  color: #999;
  text-transform: uppercase;
}

.subject-searchform input::placeholder {
  color: #999;
  text-transform: uppercase;
}

.subject-searchform button {
  background-color: transparent;
  border: none;
  color: #555;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  height: 100%;
  width: 30px;
}

.subject-searchform button:hover {
  color: #ef470d;
}

/**
 * Call to action Section 
 */
.callto-action {
  padding: 70px 0;
  background-color: #0c2e60;
}

.callto-action h3 {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.callto-action p {
  color: #fff;
  font-size: 24px;
  margin-bottom: 0;
  text-transform: capitalize;
}

@media screen and (max-width: 991px) {
  .callto-action h3 {
    font-size: 35px;
  }
  .callto-action p {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .callto-action {
    text-align: center;
  }
  .callto-action p {
    margin-bottom: 30px;
  }
}

/**
 * Nearby students section
 */
.student-search-form {
  background-color: #f6f7f8;
  position: relative;
  padding: 65px 50px;
  text-align: center;
}

.student-search-form::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  background-color: #ef470d;
  margin: auto;
  height: 2px;
  width: 100px;
}

.student-search-form h3 {
  margin-bottom: 40px;
}

.student-search-form form {
  display: block;
  width: 100%;
}

.student-search-form input {
  background-color: #fff;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  display: block;
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
}

.student-search-form input::-webkit-input-placeholder {
  color: #999;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.student-search-form input:-ms-input-placeholder {
  color: #999;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.student-search-form input::-ms-input-placeholder {
  color: #999;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.student-search-form input::placeholder {
  color: #999;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.student-search-form .btn {
  display: block;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .student-search-form {
    margin-bottom: 30px;
  }
}

/**
 * Blog section
 */
.blog-posts .post-item {
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .blog-posts .pagination {
    margin-bottom: 50px;
  }
}

.post-item {
  position: relative;
}

@media screen and (max-width: 767px) {
  .post-item {
    margin-bottom: 30px;
  }
}

.post-item .post-content {
  background-color: #fff;
  -webkit-box-shadow: 0 12px 20px rgba(1, 84, 85, 0.1);
          box-shadow: 0 12px 20px rgba(1, 84, 85, 0.1);
  border-radius: 10px;
  padding: 30px;
  position: relative;
  margin-left: auto;
  margin-top: -95px;
  width: calc(100% - 20px);
  z-index: 1;
}

.post-item .post-content h3 a {
  display: block;
  font-size: 22px;
  color: #0c2e60;
  margin-bottom: 25px;
}

.post-item .post-content h3 a:hover {
  color: #ef470d;
}

@media screen and (max-width: 1199px) {
  .post-item .post-content .meta-tags a i {
    margin-right: 10px;
  }
}

.meta-tags {
  color: #83868c;
}

.meta-tags a, .meta-tags span {
  display: inline-block !important;
  color: #83868c;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
}

@media screen and (max-width: 360px) {
  .meta-tags a, .meta-tags span {
    font-size: 11px;
  }
}

.meta-tags a i, .meta-tags span i {
  margin-right: 15px;
  color: #83868c;
}

.meta-tags a:hover, .meta-tags span:hover {
  color: #ef470d;
}

.meta-tags .commentCount {
  margin-left: 15px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .meta-tags .commentCount {
    margin-left: 0;
  }
}

@media screen and (max-width: 360px) {
  .meta-tags .commentCount {
    margin-left: 5px;
  }
}

.entry-content, .entry-content-fluid {
  position: relative;
}

.entry-content h3, .entry-content-fluid h3 {
  margin-bottom: 20px;
}

.entry-content h6, .entry-content-fluid h6 {
  margin: 40px 0 20px;
}

.entry-content .meta-tags, .entry-content-fluid .meta-tags {
  border-bottom: 1px solid #e0e4e6;
  margin: 30px 0;
  padding-bottom: 20px;
}

.entry-content .meta-tags a, .entry-content-fluid .meta-tags a {
  margin-right: 10px;
  padding-right: 10px;
  position: relative;
}

.entry-content .meta-tags a i, .entry-content-fluid .meta-tags a i {
  margin-right: 10px;
}

.entry-content .meta-tags a:not(:last-child):after, .entry-content-fluid .meta-tags a:not(:last-child):after {
  content: '';
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  bottom: 0;
  background-color: #83868c;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 3px;
  width: 3px;
}

@media screen and (min-width: 768px) {
  .entry-content-fluid {
    max-width: 540px;
    width: 100%;
  }
}

.pagination {
  border-top: 1px solid #e0e4e6;
  display: block;
  width: 100%;
  margin: 30px 0 0;
  padding-top: 30px;
}

.pagination a, .pagination span {
  display: inline-block;
  color: #999;
  border: 1px solid #e0e4e6;
  border-radius: 5px;
  padding: 7px 15px;
  margin-right: 5px;
  text-transform: uppercase;
}

.pagination a:hover, .pagination span:hover {
  border-color: #ef470d;
  color: #ef470d;
}

.pagination .current-page {
  color: #000;
  border-color: #000;
  font-weight: 500;
}

/**
 * Courses section
 */
@media screen and (max-width: 991px) {
  .courses-page .main-form input {
    widows: 100%;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}

.courses {
  position: relative;
}

.course-caro .owl-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
}

@media screen and (max-width: 991px) {
  .course-caro .owl-nav {
    display: none;
  }
}

.course-caro .owl-nav button {
  background-color: #cacaca !important;
  border-radius: 50%;
  color: #fff !important;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  height: 40px;
  width: 40px;
}

.course-caro .owl-nav button:hover {
  background-color: #256be6 !important;
}

.course-caro .owl-nav .owl-prev {
  position: absolute;
  left: -60px;
}

.course-caro .owl-nav .owl-next {
  position: absolute;
  right: -60px;
}

.single-course {
  position: relative;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);
  margin-bottom: 40px;
}

.course-thumb {
  position: relative;
}

.course-meta {
  position: relative;
}

.course-meta .btn {
  padding: 15px;
}

.ribbon {
  background-color: #ffc107;
  -webkit-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.07);
          box-shadow: 0 1px 0px rgba(0, 0, 0, 0.07);
  border-radius: 0;
  color: #000;
  display: block;
  font-size: 20px;
  position: absolute;
  padding: 7px 15px;
  top: 20px;
  left: -15px;
}

.ribbon:after {
  content: '';
  position: absolute;
  top: auto;
  left: 4px;
  right: auto;
  bottom: -7px;
  background: #bb7a03;
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
  height: 20px;
  width: 20px;
  z-index: -1;
}

.badge {
  background-color: #ffc107;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  vertical-align: middle;
  margin-left: 10px;
}

.badge i {
  margin-right: 10px;
}

.price {
  font-size: 30px;
  color: #0c2e60;
  margin-bottom: 0;
}

.price strong {
  color: #0c2e60;
}

.price span {
  color: #83868c;
  display: inline-block;
  font-size: 16px;
  text-decoration: line-through;
  margin-left: 10px;
}

.discount-price {
  display: block;
  margin-bottom: 15px;
}

.discount-price i {
  margin-right: 5px;
}

.course-align{
  margin-top: 30px;
}
/**
 * Course details section
 */
.course-details .infoBox {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.course-details .infoBox .course-meta {
  -ms-flex-preferred-size: 70%;
      flex-basis: 70%;
}

.buy-course {
  text-align: center;
}

.buy-course .btn {
  width: 100%;
}

.buy-course .btn-outline {
  border-radius: 0;
  margin-bottom: 10px;
}

.course-content {
  border: 1px solid #e0e4e6;
  border-radius: 3px;
  padding: 25px;
  position: relative;
  margin-bottom: 20px;
}

@media screen and (max-width: 575px) {
  .course-content {
    padding: 20px;
  }
}

.course-content h3 {
  font-size: 36px;
  line-height: 30px;
}

.course-content h3 a {
  font-size: 20px;
  display: block;
  color: #000;
}

.course-content h3 a:hover {
  color: #ef470d;
}

@media screen and (max-width: 575px) {
  .course-content h3 {
    font-size: 24px;
  }
}

.course-content h3 .badge {
  margin-left: 0;
}

.course-content p {
  color: #0c2e60;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 20px;
}

@media screen and (max-width: 575px) {
  .course-content p {
    font-size: 15px;
  }
}

.course-content ul li {
  display: inline-block;
  width: 49%;
}

.course-content ul li i {
  margin-right: 10px;
}

.course-content .details span {
  display: inline-block;
  position: relative;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .course-content .details span {
    font-size: 13px;
  }
}

.course-content .details span a {
  color: #f98f05;
}

.course-content .details span:after {
  content: '';
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
  background-color: #83868c;
  height: 100%;
  width: 1px;
}

.course-content .details .ratings {
  display: inline-block;
}

.course-content .enroll {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.course-content .enroll div {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.course-content .enroll .total-students {
  display: block;
  margin: 0;
}

/**
 * Course video
 */
.course-preview {
  position: relative;
  margin-bottom: 20px;
}

.course-preview:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
}

.course-preview .preview-btn {
  background-color: #ef470d;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  line-height: 80px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;
  z-index: 2;
}

/**
 * Course Curriculum
 */
.course-curriculum .nav {
  border-bottom: 0;
}

.course-curriculum .nav li {
  text-align: center;
  border-right: 1px solid #e0e4e6;
}

@media screen and (min-width: 576px) {
  .course-curriculum .nav li {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}

@media screen and (max-width: 575px) {
  .course-curriculum .nav li {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}

.course-curriculum .nav li,
.course-curriculum .nav a {
  background-color: #f7f7f7;
  display: block;
  font-size: 16px;
  color: #000;
  padding: 15px;
}

.course-curriculum .nav li.react-tabs__tab--selected,
.course-curriculum .nav a.active {
  background: -webkit-gradient(linear, left top, right top, from( #2d6968), to( #10e4e0));
  background: linear-gradient(to right, #2d6968, #10e4e0);
  color: #fff;
}

.course-curriculum .tab-pane {
  border: 1px solid #e0e4e6;
  padding: 25px;
}

.course-curriculum .tab-pane ul li {
  position: relative;
}

.course-curriculum .tab-pane ul li i {
  margin-right: 15px;
}

.course-curriculum .tab-pane .tab-title {
  margin-bottom: 20px;
}

@media screen and (min-width: 576px) {
  .course-curriculum .tab-pane .tab-list-2col li {
    width: 49%;
    display: inline-block;
  }
}

.accordion-item .accordion-heading {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #f7f8fa;
  border-bottom: 1px solid #e0e4e6;
  color: #000;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
  margin-bottom: 0;
}

.accordion-item .accordion-heading:focus {
  outline: none;
}

.accordion-item .accordion-heading span {
  font-size: 14px;
  text-transform: capitalize;
  display: inline-block;
  margin-left: auto;
}

.accordion-item .acr-body {
  padding: 15px 0;
}

.requirements{
  margin-top: 10px;
}
.requirements-subclass{
  margin-left: 15px;
}

.accordion-item .acr-body li a {
  color: #444;
/*  border-bottom: 1px solid #eeeeee;
*/ 
 display: block;
  padding: 5px 20px;
}

.sidebar .widget {
  border: 1px solid #e0e4e6;
  border-radius: 5px;
  padding: 25px;
  position: relative;
  margin-bottom: 30px;
}

.sidebar .widget .widget-title {
  text-transform: capitalize;
  margin-bottom: 20px;
}

.instractors {
  text-align: center;
}

.instractors .avatar-md {
  margin: 0 auto 20px;
}

.instractors h4 {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.instractors span {
  display: block;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 7px;
}

.socials a {
  display: inline-block;
  font-size: 14px;
  color: #83868c;
  padding-right: 10px;
}

.socials a.facebook {
  color: #4b6ea9;
}

.socials a.linkedin {
  color: #0077B5;
}

.socials a.twitter {
  color: #65bbf2;
}

.socials a.youtube {
  color: #ff0000;
}

.categories {
  padding-bottom: 40px;
}

.categories li a {
  color: #83868c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e0e4e6;
  padding: 7px 0;
}

.categories li a span {
  display: inline-block;
  margin-left: auto;
}

.categories li:last-child a {
  border-bottom: 0;
}

.recent-courses, .recent-posts {
  height: auto;
  overflow: hidden;
}

.recent-courses li:last-child, .recent-posts li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.recent-courses li a, .recent-posts li a {
  padding-left: 100px;
}

.recent-courses li a img, .recent-posts li a img {
  max-width: 80px;
}

@media screen and (max-width: 1199px) {
  .recent-courses li a span, .recent-posts li a span {
    font-size: 11px;
  }
}

@media screen and (max-width: 991px) {
  .recent-posts li a {
    padding: 10px 0 10px 100px;
  }
}

@media screen and (max-width: 420px) {
  .recent-posts li a {
    padding: 0 0 0 100px;
  }
}

/**
 * Comments
 */
.comments {
  padding: 50px 0;
}

.comments .comment-title {
  color: #2f2f35;
  text-transform: capitalize;
  padding-bottom: 0;
  margin-bottom: 30px;
}

.comments ul {
  list-style: none;
}

.comments ul li {
  position: relative;
  margin-bottom: 34px;
}

.comments ul li h4 {
  color: #2f2f35;
  font-size: 18px;
  margin-bottom: 4px;
}

.comments ul li a {
  color: #1577df;
  display: inline-block;
  font-size: 14px;
}

.comments ul li a:first-of-type {
  padding-left: 0;
}

.comments ul .cmnt-level-2 {
  padding-left: 50px;
}

.comments p {
  color: #2f2f35;
  margin-bottom: 10px;
}

@media screen and (max-width: 575px) {
  .comments p {
    font-size: 14px;
  }
}

.comments .single-comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
}

.comments .comments-body {
  border-bottom: 1px solid #e0e4e6;
  -ms-flex-preferred-size: calc(100% - 80px);
      flex-basis: calc(100% - 80px);
  padding-bottom: 25px;
}

.comments .comments-body a {
  color: #ef470d;
}

.comments .user-thumb {
  border-radius: 50%;
  -ms-flex-preferred-size: 60px;
      flex-basis: 60px;
}

.comment-form .comment-title {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .comment-form {
    margin-bottom: 50px;
  }
}

.comment-form form textarea {
  background-color: #f6f6f6;
  border: 1px solid #dadada;
  border-radius: 5px;
  margin-bottom: 25px;
  padding: 20px;
  width: 100%;
  min-height: 130px;
}

.comment-form form .btn {
  display: block;
  min-width: auto;
  margin: 0;
  margin-left: 0px;
  padding-left: 40px;
  padding-right: 40px;
}

/**
 * Testimonial section
 */
/* .testimonial {
  background: url(../images/slider/2.jpg) top center/cover;
} */

.testimonial .container {
  position: relative;
  z-index: 1;
}

.testimonial:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(linear, left top, right bottom, from(rgba(12, 46, 96, 0.8)), to(rgba(239, 71, 13, 0.5)));
  background: linear-gradient(to right bottom, rgba(12, 46, 96, 0.8), rgba(239, 71, 13, 0.5));
  height: 100%;
  width: 100%;
  z-index: 0;
}

.testimonial .testimonialBox {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  padding: 30px;
  text-align: center;
}

.testimonial .testimonialBox .quote-sign {
  color: #f8f8f8;
  font-size: 62px;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
}

.testimonial .single-testimonial {
  position: relative;
  max-width: 770px;
  margin: 30px auto 0;
}

.testimonial .single-testimonial p {
  font-style: italic;
  margin: 0;
}

.testimonial .testimonial-user {
  display: block;
  margin-top: 30px;
}

.testimonial .testimonial-user .avatar-small {
  margin: 0 auto 15px;
  width: 60px !important;
}

.testimonial .testimonial-user strong {
  color: #0c2e60;
}

.testimonial .owl-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
}

@media screen and (max-width: 991px) {
  .testimonial .owl-nav {
    display: none;
  }
}

.testimonial .owl-nav button {
  background-color: #cacaca !important;
  border-radius: 50%;
  color: #fff !important;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  height: 40px;
  width: 40px;
}

.testimonial .owl-nav button:hover {
  background-color: #256be6 !important;
}

.testimonial .owl-nav .owl-prev {
  position: absolute;
  left: -130px;
}

.testimonial .owl-nav .owl-next {
  position: absolute;
  right: -130px;
}

/**
 * Funfacts section
 */
.funfacts, .funfacts-02 {
  border-bottom: 1px solid #e0e4e6;
  padding: 0;
}

@media screen and (max-width: 479px) {
  .funfacts, .funfacts-02 {
    border: 0;
  }
}

.funfacts ul, .funfacts-02 ul {
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.funfacts ul li, .funfacts-02 ul li {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  padding: 60px 0;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .funfacts ul li, .funfacts-02 ul li {
    padding: 30px 0;
  }
}

.funfacts ul li:not(:last-child), .funfacts-02 ul li:not(:last-child) {
  border-right: 1px solid #e0e4e6;
}

.funfacts ul li span, .funfacts-02 ul li span {
  font-size: 48px;
  font-weight: 500;
  line-height: 46px;
  color: #0c2e60;
}

@media screen and (max-width: 991px) {
  .funfacts ul li span, .funfacts-02 ul li span {
    font-size: 38px;
  }
}

.funfacts ul li p, .funfacts-02 ul li p {
  margin-bottom: 0;
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
}

@media screen and (max-width: 991px) {
  .funfacts ul li p, .funfacts-02 ul li p {
    font-size: 13px;
    letter-spacing: .8px;
  }
}

@media screen and (max-width: 767px) {
  .funfacts ul li, .funfacts-02 ul li {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
  }
  .funfacts ul li:nth-child(2), .funfacts-02 ul li:nth-child(2) {
    border: 0;
  }
}

@media screen and (max-width: 479px) {
  .funfacts ul li, .funfacts-02 ul li {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    border: 0 !important;
  }
  .funfacts ul li:after, .funfacts-02 ul li:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e0e4e6;
    width: 100px;
    height: 1px;
    margin: 0 auto;
  }
}

.funfacts-02 {
  border-bottom: 0;
  padding: 70px 0;
}

.funfacts-02 ul li {
  padding: 0;
}

@media screen and (max-width: 767px) {
  .funfacts-02 ul li {
    padding: 15px 0;
  }
  .funfacts-02 ul li:last-child {
    padding-bottom: 0;
  }
}

/**
 * Newsletter section
 */
.newsletter {
  position: relative;
  margin-top: 40px;
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.newsletter input {
  background-color: #fff;
  border: none;
  border-radius: 50px 0 0 50px;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 15px 25px;
  -ms-flex-preferred-size: calc(100% - 170px);
      flex-basis: calc(100% - 170px);
}

@media screen and (max-width: 479px) {
  .newsletter input {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    border-radius: 50px;
    margin-bottom: 15px;
  }
}

.newsletter button {
  background: -webkit-gradient(linear, left top, right top, from(#ef3e0f), to(#ffb800));
  background: linear-gradient(to right, #ef3e0f, #ffb800);
  border: none;
  border-radius: 0 50px 50px 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  -ms-flex-preferred-size: 170px;
      flex-basis: 170px;
  text-transform: uppercase;
  padding: 15px 25px;
}

.newsletter button i {
  display: inline-block;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-right: 10px;
}

@media screen and (max-width: 479px) {
  .newsletter button {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    border-radius: 50px;
  }
}

/**
 * Contact section
 */
.contact form .form-group {
  margin-bottom: 35px;
}

.contact form label {
  color: #0c2e60;
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.contact form input, .contact form textarea {
  border: 1px solid #e0e4e6;
  border-radius: 0;
  padding: 10px 15px;
  height: auto;
}

.contact form input::-webkit-input-placeholder, .contact form textarea::-webkit-input-placeholder {
  color: #999;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
}

.contact form input:-ms-input-placeholder, .contact form textarea:-ms-input-placeholder {
  color: #999;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
}

.contact form input::-ms-input-placeholder, .contact form textarea::-ms-input-placeholder {
  color: #999;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
}

.contact form input::placeholder, .contact form textarea::placeholder {
  color: #999;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
}

.contact form textarea {
  min-height: 240px;
}

.contact form .btn {
  display: block;
  margin: 0 auto;
}

.contact-info {
  margin-top: 27px;
}

.contact-info div {
  margin-top: 11px;
}

.contact-info div h6 {
  font-size: 15px;
  text-transform: capitalize;
}

.contact-info div a, .contact-info div span {
  font-size: 13px;
  color: #83868c;
  display: block;
  line-height: 18px;
}

.map-section {
  padding-bottom: 0;
}

#map {
  min-height: 400px;
  height: 100%;
  overflow: hidden;
}

.widget .widget-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 35px;
}

.widget p {
  color: #999;
}

.widget-navigation li a {
  color: #999;
  display: block;
  font-weight: 300;
  margin-bottom: 5px;
}

.widget-navigation li a:hover {
  color: #0c2e60;
}

@media screen and (max-width: 767px) {
  .widget-navigation {
    margin-bottom: 30px;
  }
}

.widget-insta-feed ul {
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}

.widget-insta-feed ul li {
  -ms-flex-preferred-size: calc(100% / 3 - 10px);
      flex-basis: calc(100% / 3 - 10px);
  margin-right: 10px;
  margin-bottom: 10px;
}

.widget-insta-feed ul li:nth-child(3n - 3) {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .widget-insta-feed {
    margin-bottom: 30px;
  }
}

.widget-address address {
  position: relative;
}

.widget-address span, .widget-address a {
  color: #999;
  display: block;
  line-height: 23px;
  position: relative;
  padding-left: 30px;
}

.widget-address span i, .widget-address a i {
  color: #fff;
  position: absolute;
  left: 0;
  top: 4px;
}

.widget-address a {
  margin-top: 10px;
}

.widget_search {
  padding: 0 !important;
  border: none !important;
}

.widget_search form {
  position: relative;
}

.widget_search form i {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.widget_search input {
  background-color: none;
  border: 1px solid #e0e4e6;
  border-radius: 5px;
  padding: 15px 40px 15px 25px;
  width: 100%;
}

/*************************
 * Pricing section
 */
.pricing-box {
  border: 1px solid #e0e4e6;
  border-radius: 5px;
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .pricing-box {
    margin-bottom: 30px;
  }
}

.pricing-box .p-head {
  background-color: #f9f9f9;
  padding: 40px 25px;
  text-align: center;
}

.pricing-box .p-head h4 {
  margin-bottom: 25px;
  text-transform: capitalize;
}

.pricing-box .p-head > span {
  font-size: 18px;
}

.pricing-box .price {
  font-family: "Poppins", sans-serif;
  font-size: 56px;
  font-weight: 500;
  margin-bottom: 10px;
}

.pricing-box .price span {
  font-weight: 400;
  text-decoration: none;
  margin-left: 0;
}

.pricing-box .p-body {
  padding: 25px;
}

.pricing-box .p-body ul {
  margin-bottom: 30px;
}

.pricing-box .p-body li {
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid #e0e4e6;
}

.pricing-box .p-body li i {
  margin-right: 10px;
}

.pricing-box .p-body li i.ti-check {
  color: #3fd35e;
}

.pricing-box .p-body li i.ti-close {
  color: #e53131;
}

.pricing-box .btn {
  display: block;
}

/*************************
 * Page Feature
 */
.page-feature {
  /* background: url(../images/slider/contact.jpg) no-repeat top center/cover; */
  padding: 100px 0;
  position: relative;
}

.page-feature::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-color: rgba(0, 0, 0, 0.75);*/
  height: 100%;
  width: 100%;
}

.page-feature h2 {
  color: black;
  text-transform: capitalize;
  position: relative;
  z-index: 3;
}

.page-feature .breadcrumb {
  background-color: transparent;
  color: black;
  display: block;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 0;
  z-index: 3;
}

.page-feature .breadcrumb a {
  color: black;
}

/*************************
 * Login / Registration
 */
@media screen and (min-width: 1200px) {
  .login-registration {
    padding: 10% 0 5%;
  }
}

.login-registration .content-box, .login-registration .content-box-fluid {
  margin-bottom: 30px;
}

.sl-form {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .sl-form {
    margin: 0 auto;
  }
}

.sl-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.sl-form .form-group label {
  color: #0c2e60;
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.sl-form .form-group input:not([type="checkbox"]) {
  border: 1px solid #e0e4e6;
  display: block;
  padding: 10px 15px;
  width: 100%;
}

.sl-form .form-check {
  margin-bottom: 30px;
}

.sl-form .form-check label {
  color: #000;
}

.sl-form .form-check label a {
  color: #000;
}

.sl-form .form-check label a:hover {
  text-decoration: underline;
}

.sl-form .form-check input {
  margin-top: 8px;
}

.sl-form .btn {
  margin-bottom: 30px;
  text-transform: uppercase;
}

.sl-form .notice {
  color: #000;
}

.sl-form .notice a {
  color: #ef470d;
}

.sl-form .notice a:hover {
  text-decoration: underline;
}

/*************************
 * Trial section 
 */
.trial-sec {
  position: relative;
}

.form {
  background-color: transparent;
  position: relative;
  padding: 25px;
}

.form-title {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 32px;
}

.form input, .form select, .form textarea {
  border: 1px solid #e0e4e6;
  padding: 10px 15px;
  margin-bottom: 30px;
  width: 100%;
}

.form textarea {
  resize: none;
}

.trial-form {
  background-color: #fff;
  border-radius: 3px;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  padding: 40px 25px;
  width: 100%;
  z-index: 1;
}

.trial-form .form-title {
  color: #fff;
  padding: 0 0 30px;
  position: relative;
  z-index: 1;
}

.trial-form:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffb800;
  -webkit-clip-path: circle(100% at 50% -170px);
          clip-path: circle(100% at 50% -170px);
  height: 130px;
  width: 100%;
  z-index: 0;
}

.trial-form button {
  width: 100%;
}


@media screen and (max-width: 991px) {
  .trial-form:before {
    -webkit-clip-path: circle(100% at 50% -120px);
            clip-path: circle(100% at 50% -120px);
  }
}

@media screen and (max-width: 360px) {
  .trial-form:before {
    -webkit-clip-path: circle(100% at 50% -90px);
            clip-path: circle(100% at 50% -90px);
  }
}

@media screen and (max-width: 767px) {
  .trial-form {
    margin: auto;
  }
}

.coupon-title {
  font-size: 42px;
  text-transform: uppercase;
}

.coupon-title span {
  display: inline-block;
  font-size: 16px;
}

.coupon p {
  color: #000;
  margin-bottom: 40px;
}

.coupon p strong {
  text-transform: uppercase;
  color: #0c2e60;
}

.coupon .text {
  color: #000;
  text-transform: capitalize;
  display: block;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .coupon {
    text-align: center;
    margin-bottom: 30px;
  }
}

.clock {
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 400px;
}

.clock p {
  color: #ef470d;
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
}

.clock span {
  color: #222;
  display: block;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1.3px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .clock {
    margin: 0 auto;
  }
}

/*************************
 * Event section
 */
.event-group {
  position: relative;
  margin-bottom: 60px;
}

.event-group .title {
  text-transform: uppercase;
  border-left: 3px solid #0c2e60;
  padding-left: 20px;
  margin-bottom: 25px;
}

.event-group:last-child {
  margin-bottom: 0;
}

.event-item {
  padding: 25px;
  background-color: #f8f9fa;
  border-radius: 5px;
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.event-item:not(:last-child) {
  margin-bottom: 15px;
}

.event-item .e-date {
  background-color: #ddd;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-preferred-size: 60px;
      flex-basis: 60px;
  font-size: 32px;
  text-align: center;
  margin: 0;
}

.event-item .e-date span {
  display: block;
  font-size: 16px;
}

.event-item .e-time {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.event-item .e-time i {
  margin-right: 10px;
  vertical-align: text-top;
}

.event-item .e-content {
  -ms-flex-preferred-size: calc(100% - 60px);
      flex-basis: calc(100% - 60px);
  padding-left: 20px;
}

.event-item .e-content .meta-tags {
  margin-bottom: 20px;
}

.event-item .e-content h3 a {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #000;
}

.event-item .e-content p {
  margin-bottom: 0;
}

@media screen and (max-width: 991px) {
  .event-img-big {
    margin-bottom: 40px;
  }
}

/* 
 * Event item colors
 */
.upcoming {
  background-color: #06979d !important;
}
.ongoing {
  background-color: #fdc800 !important;
}
.expired {
  background-color: #fd5a5a !important;
}

/*************************
 * Event Details section
 */
.events-details .e-content {
  display: block;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  padding-left: 0;
}

.events-details .e-content p:not(:last-child) {
  margin-bottom: 20px;
}

.events-details #map {
  margin-top: 40px;
  height: auto;
}

@media screen and (max-width: 991px) {
  .events-details #map {
    margin-bottom: 40px;
  }
}

/*************************
 * Footer
 */
.footer .widget .widget-title {
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .footer .widget {
    margin-bottom: 30px;
  }
}

.footer .copyright {
  color: #83868c;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  padding: 20px 0;
}

.footer .copyright a {
  color: #ef470d;
}

.foo-top {
  background-color: #f3f3f3;
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .foo-top {
    padding-bottom: 20px;
  }
}

.notice-board {
  position: relative;
  text-align: center;
  padding: 10% 0;
  max-width: 760px;
  margin: 0 auto;
}

.notice-board h1 {
  margin-bottom: 40px;
}

.notice-board h1 span {
  color: #fdc800;
}

.notice-board h6 {
  margin-bottom: 10px;
}

.notice-board p {
  margin-bottom: 50px;
}

.contact-details{
  text-align: right;
  
}
.contact-details a{
  color: red;
}